import imgFcseSvg from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering.svg'
import imgFcsePng5x from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering.png'
import imgFcsePng from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering-medium.png'
import imgFcsePng2x from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering-large.png'
import imgFcseJpg5x from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering.jpg'
import imgFcseJpg from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering-medium.jpg'
import imgFcseJpg2x from '../../images/mediaresources/fcse/logo-freshcells-systems-engineering-large.jpg'

import imgFreshMsSvg from '../../images/mediaresources/freshms/freshMS.svg'
import imgFreshMsPng from '../../images/mediaresources/freshms/freshMS.png'
import imgFreshMsPng2x from '../../images/mediaresources/freshms/freshMS@2x.png'
import imgFreshMsPng5x from '../../images/mediaresources/freshms/freshMS@.5x.png'
import imgFreshMsJpg from '../../images/mediaresources/freshms/freshMS.jpg'
import imgFreshMsJpg2x from '../../images/mediaresources/freshms/freshMS@2x.jpg'
import imgFreshMsJpg5x from '../../images/mediaresources/freshms/freshMS@.5x.jpg'

import imgFusionSvg from '../../images/mediaresources/fusion/Fusion.svg'
import imgFusionPng from '../../images/mediaresources/fusion/Fusion.png'
import imgFusionPng2x from '../../images/mediaresources/fusion/Fusion@2x.png'
import imgFusionPng5x from '../../images/mediaresources/fusion/Fusion@.5x.png'
import imgFusionJpg from '../../images/mediaresources/fusion/Fusion.jpg'
import imgFusionJpg2x from '../../images/mediaresources/fusion/Fusion@2x.jpg'
import imgFusionJpg5x from '../../images/mediaresources/fusion/Fusion@.5x.jpg'

import imgMightyCSvg from '../../images/mediaresources/mightyc/mightyC.svg'
import imgMightyCPng from '../../images/mediaresources/mightyc/mightyC.png'
import imgMightyCPng2x from '../../images/mediaresources/mightyc/mightyC@2x.png'
import imgMightyCPng5x from '../../images/mediaresources/mightyc/mightyC@.5x.png'
import imgMightyCJpg from '../../images/mediaresources/mightyc/mightyC.jpg'
import imgMightyCJpg2x from '../../images/mediaresources/mightyc/mightyC@2x.jpg'
import imgMightyCJpg5x from '../../images/mediaresources/mightyc/mightyC@.5x.jpg'

import imgQuickstartSvg from '../../images/mediaresources/quickstart/QuickStart.svg'
import imgQuickstartPng from '../../images/mediaresources/quickstart/QuickStart.png'
import imgQuickstartPng2x from '../../images/mediaresources/quickstart/QuickStart@2x.png'
import imgQuickstartPng5x from '../../images/mediaresources/quickstart/QuickStart@.5x.png'
import imgQuickstartJpg from '../../images/mediaresources/quickstart/QuickStart.jpg'
import imgQuickstartJpg2x from '../../images/mediaresources/quickstart/QuickStart@2x.jpg'
import imgQuickstartJpg5x from '../../images/mediaresources/quickstart/QuickStart@.5x.jpg'

import imgTsboSvg from '../../images/mediaresources/tsbo/TSBO.svg'
import imgTsboPng from '../../images/mediaresources/tsbo/TSBO.png'
import imgTsboPng2x from '../../images/mediaresources/tsbo/TSBO@2x.png'
import imgTsboPng5x from '../../images/mediaresources/tsbo/TSBO@.5x.png'
import imgTsboJpg from '../../images/mediaresources/tsbo/TSBO.jpg'
import imgTsboJpg2x from '../../images/mediaresources/tsbo/TSBO@2x.jpg'
import imgTsboJpg5x from '../../images/mediaresources/tsbo/TSBO@.5x.jpg'

export const mediaList = [
  {
    title: 'Company logo',
    svg: imgFcseSvg,
    png: imgFcsePng,
    png2: imgFcsePng2x,
    png5: imgFcsePng5x,
    jpg: imgFcseJpg,
    jpg2: imgFcseJpg2x,
    jpg5: imgFcseJpg5x
  },
  {
    title: 'travelsandbox®',
    svg: imgTsboSvg,
    png: imgTsboPng,
    png2: imgTsboPng2x,
    png5: imgTsboPng5x,
    jpg: imgTsboJpg,
    jpg2: imgTsboJpg2x,
    jpg5: imgTsboJpg5x
  },
  {
    title: 'QuickStart',
    svg: imgQuickstartSvg,
    png: imgQuickstartPng,
    png2: imgQuickstartPng2x,
    png5: imgQuickstartPng5x,
    jpg: imgQuickstartJpg,
    jpg2: imgQuickstartJpg2x,
    jpg5: imgQuickstartJpg5x
  },
  {
    title: 'freshMS',
    svg: imgFreshMsSvg,
    png: imgFreshMsPng,
    png2: imgFreshMsPng2x,
    png5: imgFreshMsPng5x,
    jpg: imgFreshMsJpg,
    jpg2: imgFreshMsJpg2x,
    jpg5: imgFreshMsJpg5x
  },
  {
    title: 'Fusion',
    svg: imgFusionSvg,
    png: imgFusionPng,
    png2: imgFusionPng2x,
    png5: imgFusionPng5x,
    jpg: imgFusionJpg,
    jpg2: imgFusionJpg2x,
    jpg5: imgFusionJpg5x
  },
  {
    title: 'mightyC®',
    svg: imgMightyCSvg,
    png: imgMightyCPng,
    png2: imgMightyCPng2x,
    png5: imgMightyCPng5x,
    jpg: imgMightyCJpg,
    jpg2: imgMightyCJpg2x,
    jpg5: imgMightyCJpg5x
  }
]

