/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Media resources',
  titlePng: 'Download PNG',
  titleJpg: 'Download JPG',
  titleSvg: 'Download',
  donwloadPngLarge: 'large',
  donwloadJpgLarge: 'large',
  donwloadSvg: 'SVG',
  donwloadMedium: 'medium',
  donwloadSmall: 'small'
})
