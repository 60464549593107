// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

import messages from './MediaResources.lang'
import baseStyles from './MediaResources.scss'

import { mediaList } from './data'

type Props = {
  ...StyleProps
}

const NewsList = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
      <div className={styles.list}>
        {mediaList.map((item, i) => (
          <div className={styles.item} key={i}>
            <div className={styles.itemTitle}>{item.title}</div>
            <div className={styles.itemImgWrapper}>
              <img src={item.svg} alt={item.title} className={styles.itemImg} />
            </div>
            <div className={styles.downloads}>
              <div className={styles.downloadsRow}>
                <p className={styles.downloadType}>{formatMessage(messages.titlePng)}</p>
                <a href={item.png2} download>
                  {formatMessage(messages.donwloadPngLarge)}
                </a>
                <span className={styles.downloadsSep}>•</span>
                <a href={item.png} download>
                  {formatMessage(messages.donwloadMedium)}
                </a>
                <span className={styles.downloadsSep}>•</span>
                <a href={item.png5} download>
                  {formatMessage(messages.donwloadSmall)}
                </a>
              </div>
              <div className={styles.downloadsRow}>
                <p className={styles.downloadType}>{formatMessage(messages.titleJpg)}</p>
                <a href={item.jpg2} download>
                  {formatMessage(messages.donwloadJpgLarge)}
                </a>
                <span className={styles.downloadsSep}>•</span>
                <a href={item.jpg} download>
                  {formatMessage(messages.donwloadMedium)}
                </a>
                <span className={styles.downloadsSep}>•</span>
                <a href={item.jpg5} download>
                  {formatMessage(messages.donwloadSmall)}
                </a>
              </div>
              <p className={styles.downloadType}>{formatMessage(messages.titleSvg)}</p>
              <div className={styles.downloadsRow}>
                <a href={item.svg} download>
                  {formatMessage(messages.donwloadSvg)}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(NewsList)
