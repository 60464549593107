// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Button from '../../basic/Button'
import Device from '../../basic/Device'
import ArticleMeta from '../ArticleMeta'

import { newsList } from '../data'

import messages from './NewsList.lang'
import baseStyles from './NewsList.scss'

type Props = {
  ...StyleProps
}

const NewsList = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
      <div className={styles.list}>
        {Object.keys(newsList).map((itemKey, i) => (
          ((locale === 'en' && !newsList[itemKey].translation) || newsList[itemKey].skip) ? null : (
            <div className={styles.item} key={i}>
              <div className={styles.imgWrap}>{newsList[itemKey].teaserImg}</div>
              <div className={styles.content}>
                <div>
                  <Device phone>
                    <ArticleMeta date={newsList[itemKey].date} publisher={newsList[itemKey].publisher} />
                  </Device>
                </div>
                <h3 className={styles.itemTitle}>{formatMessage(newsList[itemKey].title)}</h3>
                <p className={styles.itemTeaser}>{formatMessage(newsList[itemKey].teaserText)}</p>
                <div className={styles.itemFooter}>
                  <div>
                    <Device tablet desktop>
                      <ArticleMeta date={newsList[itemKey].date} publisher={newsList[itemKey].publisher} />
                    </Device>
                  </div>
                  <div className={styles.action}>
                    {newsList[itemKey].location && (
                      <Link to={newsList[itemKey].location} className={styles.btnLink}>
                        <Button
                          styles={styles.btn}
                          tag="span"
                          color="accent"
                          iconRight="icon-arrow_03-right-medium"
                          block
                        >
                          {formatMessage(messages.readMore)}
                        </Button>
                      </Link>
                    )}
                    {newsList[itemKey].externalLocation && (
                      <a
                        href={newsList[itemKey].externalLocation}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.btnLink}
                      >
                        <Button
                          styles={styles.btn}
                          tag="span"
                          color="accent"
                          iconRight="icon-arrow_03-right-medium"
                          block
                        >
                          {formatMessage(messages.readMore)}
                        </Button>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(NewsList)
