// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import SubpageHeroTeaser from '../SubpageHeroTeaser'

import IntroTeaser from '../IntroTeaser'
import NewsList from './NewsList'
import MediaResources from '../MediaResources'
import ContactTeaser from '../ContactTeaser'

import messages from './NewsPage.lang'
import baseStyles from './NewsPage.scss'
import { StaticImage } from 'gatsby-plugin-image'

const IntroImage = (
  <StaticImage src="../../images/pictures/news_intro_image.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/news_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const NewsPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} imgAlt="office gallery" />
      <Container>
        <IntroTeaser
          title={formatMessage(messages.introTitle)}
          copy={<p>{formatMessage(messages.introInfo)}</p>}
          image={IntroImage}
          styles={styles.intro}
        />
        <NewsList />
        <MediaResources />
        <ContactTeaser />
      </Container>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(NewsPage)
